<template>
  <el-dialog
      :title="title"
      :visible.sync="dialogFormVisible"
      width="60%">
    <el-form ref="form" :model="formAdd" :rules="ruleCar" class="info">
      <el-row :gutter="20">
        <el-col :span="12">
          <el-form-item label="车牌" :label-width="formLabelWidth" prop="carId">
            <el-select
                v-model="formAdd.carId"
                filterable
                remote
                reserve-keyword
                placeholder="请输入车牌关键字"
                :remote-method="remoteMethod"
                :loading="loading"
                @change="pickCarNum"
                v-if="disabled== false"
            >
              <el-option
                  v-for="item in carList"
                  :key="item.id"
                  :label="item.carNum"
                  :value="item.id">
              </el-option>
            </el-select>
            <el-input v-model="formAdd.carNum" autocomplete="off" placeholder="车牌信息"   maxlength="6"  :disabled="disabled" v-if="disabled== true"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="品牌型号" :label-width="formLabelWidth" prop="carModel" >
            <el-cascader
                :disabled="disabled"
                v-model="formAdd.carModel"
                placeholder="试试搜索"
                :options="options"
                @change="handleBrand"
                filterable></el-cascader>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="负责人" :label-width="formLabelWidth" prop="driverid">
            <el-select v-model="formAdd.driverid" placeholder="请选择负责人"  >
              <el-option
                  v-for="item in hList"
                  :key="item.id"
                  :label="item.realName"
                  :value="item.userId"
                  @change="pickCharger"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="签约客户" :label-width="formLabelWidth" prop="driverId">
            <el-select v-model="formAdd.driverId"
                       filterable
                       remote
                       reserve-keyword
                       placeholder="请搜索签约客户"
                       :remote-method="remoteCustomer"
                       :loading="loading"
                       @change="pickCustomer"
                       v-if="disabled== false"
            >
              <el-option
                  v-for="item in cList"
                  :key="item.id"
                  :label="item.customerName"
                  :value="item.id">
              </el-option>
            </el-select>
            <el-input v-model="formAdd.customerName" autocomplete="off" placeholder="签约用户名"   maxlength="6"  :disabled="disabled" v-if="disabled== true"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="联系方式" :label-width="formLabelWidth" prop="customerPhone">
            <el-input v-model="formAdd.customerPhone" autocomplete="off" placeholder="请输签约用户联系方式" maxlength="11"  :disabled="disabled"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="合约编号" :label-width="formLabelWidth" prop="contractNum">
            <el-input v-model="formAdd.contractNum" autocomplete="off" placeholder="请输合约编号"  :disabled="disabled"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <el-form ref="form" :model="formAdd" :rules="ruleCar" class="info">
      <el-row :gutter="20">
        <el-col :span="12">
          <el-form-item label="签约类型" :label-width="formLabelWidth" prop="signType">
            <el-select v-model="formAdd.signType" placeholder="请选择签约类型">
              <el-option  label="短租" :value="4" ></el-option>
              <el-option  label="全款购车" :value="1" ></el-option>
              <el-option  label="以租代购" :value="2" ></el-option>
              <el-option  label="分期购车" :value="3" ></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="签订时间" :label-width="formLabelWidth" prop="signDate">
            <el-date-picker
                v-model="formAdd.signDate"
                type="date"
                value-format="yyyy-MM-dd"
                placeholder="请选择年检时间">
            </el-date-picker>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="开始时间" :label-width="formLabelWidth" prop="beginTime">
            <el-date-picker
                v-model="formAdd.beginTime"
                type="date"
                value-format="yyyy-MM-dd"
                placeholder="请选择开始时间">
            </el-date-picker>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="结束时间" :label-width="formLabelWidth" prop="endTime">
            <el-date-picker
                v-model="formAdd.endTime"
                type="date"
                value-format="yyyy-MM-dd"
                placeholder="请选择结束时间">
            </el-date-picker>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <el-form ref="form" :model="formAdd" :rules="ruleCar" class="info">
      <el-row :gutter="20">
        <el-col :span="12">
          <el-form-item label="付款方式" :label-width="formLabelWidth" prop="contractType">
            <el-radio-group v-model="formAdd.contractType">
              <el-radio-button label="1">周付</el-radio-button>
              <el-radio-button label="2">月付</el-radio-button>
            </el-radio-group>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="付款日" :label-width="formLabelWidth" prop="payDate">
            <el-date-picker
                v-model="formAdd.payDate"
                type="date"
                value-format="yyyy-MM-dd"
                placeholder="请选择结付款日">
            </el-date-picker>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="分款期数" :label-width="formLabelWidth" prop="signPeriods">
            <el-input v-model="formAdd.signPeriods" autocomplete="off" placeholder="请输入分期期数" >
              <span slot="append">期</span>
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="每期租金" :label-width="formLabelWidth" prop="rent">
            <el-input v-model="formAdd.rent" autocomplete="off" placeholder="请输入每期租金" >
              <span slot="append">元</span>
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="押金" :label-width="formLabelWidth" prop="depost">
            <el-input v-model="formAdd.depost" autocomplete="off" placeholder="请输入押金" >
              <span slot="append">元</span>
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="总金额" :label-width="formLabelWidth" prop="totalAmount">
            <el-input v-model="moneyAll" autocomplete="off" placeholder="请输入总金额" >
              <span slot="append">元</span>
            </el-input>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <el-form ref="form" :model="formAdd" :rules="ruleCar" class="info">
      <el-row :gutter="20">
        <el-col :span="12">
          <el-form-item label="到期提醒" :label-width="formLabelWidth" prop="remindDay">
            <el-radio-group v-model="formAdd.remindDay">
              <el-radio-button label="1">提前15天</el-radio-button>
              <el-radio-button label="2">提前30天</el-radio-button>
            </el-radio-group>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item label="备注" :label-width="formLabelWidth" >
            <el-input v-model="formAdd.remark" autocomplete="off" placeholder="请输入备注"
                      type="textarea"
                      rows="6"
                      maxlength="200"
                      show-word-limit
                      resize="none"
            >
            </el-input>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>

    <div slot="footer" class="dialog-footer">
      <el-button @click="close">取 消</el-button>
      <el-button type="primary" @click="save">确 定</el-button>
    </div>
  </el-dialog>
</template>

<script>
import {getCList} from "@/utils/api/contact";
import { getList as getCustomer } from "@/utils/api/customer";
import {getCarList, getResponsible} from "@/utils/api/dict";
import {getCarModeSelectVo} from "@/utils/api/car";
import {getDetail} from "@/utils/api/sign";
export default {

  name: "EditSign",
  data(){
    var checkPlate = (rule, value, callback) => {
      let reg_plate =  /^[沪A-Z]{1}[A-Z]{1}(([A-Z0-9]{4}[A-Z0-9挂学警港澳]{1}$)|([0-9]{5}[DF]$)|([DF][A-HJ-NP-Z0-9][0-9]{4}$))/; //普通车牌 | 新能源货车 | 新能源小客车 车牌校验规则
      if (!reg_plate.test(value)) {
        callback(new Error("请输入正确的车牌"));
      } else {
        callback();
      }
    };
    var checkPhone = (rule, value, callback) => {
      let reg_mobile = /^1[3|4|5|7|8|9][0-9]\d{8}$/;
      let reg_phone = /^(0d{2,3})-?(d{7,8})$/;
      if (!reg_mobile.test(value)) {
        callback(new Error("请输入正确的手机或座机号码"));
      } else {
        callback();
      }
      if (!reg_phone.test(value)) {
        callback(new Error("请输入正确的手机或座机号码"));
      } else {
        callback();
      }
    };
    return{
      edit:0,
      dialogFormVisible: false,
      title: '新增',
      formLabelWidth: "80px",
      labelPosition: "right",
      hList: [],//平台列表
      cList: [],//客户列表
      carList:[],
      value: [],
      list: [],
      options: [],
      loading: false,
      disabled:false,
      formAdd:{
        carId:"",//车牌编号
        carNum:"",//车牌
        carModel:"",//型号
        carBrand:"",//品牌
        driverid:"",//负责人编号
        chargerName:"",//负责人
        driverId:'',//签约客户
        customerPhone:'',//签约客户电话
        contractNum:"",//合约编号
        signType:4,//签约类型
        signDate:"",//签约日期
        beginTime:"",//开始时间
        endTime:"",//结束时间
        contractType:"1",//付款方式
        payDate:"",//付款日
        signPeriods:"",//分期期数
        rent:"",//每期租金
        depost:"",//押金
        totalAmount:"",//总金额
        remindDay:"1",//到期提醒
      },
      ruleCar:{
        carId:[
          { required: true, message: "请输入车牌号码", trigger: 'blur' },
          { validator: checkPlate, trigger: ['blur']  }
        ],
        carModel:[
          { required: true, message: "请选择品牌型号", trigger: 'blur' },
        ],
        carBrand:[
          { required: true, message: "请选择品牌型号", trigger: 'blur' },
        ],
        driverid:[
          { required: true, message: "请选择负责人", trigger: 'blur' },
        ],
        driverId:[
          { required: true, message: "请选择签约客户", trigger: 'blur' },
        ],
        customerPhone:[
          { required: true, message: "请填写联系人电话", trigger: 'blur' },
          { validator: checkPhone, trigger: ['blur']  }
        ],
        contractNum:[
          { required: true, message: "请填写合同编号", trigger: 'blur' },
        ],
        signType:[
          { required: true, message: "请选择签约类型", trigger: 'blur' },
        ],
        signDate:[
          { required: true, message: "请选择签约日期", trigger: 'blur' },
        ],
        beginTime:[
          { required: true, message: "请选择开始日期", trigger: 'blur' },
        ],
        endTime:[
          { required: true, message: "请选择结束日期", trigger: 'blur' },
        ],
        contractType:[
          { required: true, message: "请选择付款方式", trigger: 'blur' },
        ],
        payDate:[
          { required: true, message: "请选择付款日期", trigger: 'blur' },
        ],
        signPeriods:[
          { required: true, message: "请填写分期期数", trigger: 'blur' },
        ],
        rent:[
          { required: true, message: "请填写每期租金", trigger: 'blur' },
        ],
        depost:[
          { required: true, message: "请填写押金", trigger: 'blur' },
        ],
        totalAmount:[
          { required: true, message: "请填写总金额", trigger: 'blur' },
        ],
        remindDay:[
          { required: true, message: "请设置到期提醒", trigger: 'blur' },
        ]
      }
    }
  },
  created() {
    this.getCarModeSelectVo()
    this.getResponsible()
  },
  computed: {
    moneyAll(){
      let date = this.formAdd.depost*1+((this.formAdd.rent*1)*(this.formAdd.signPeriods*1))
      return date
    }
  },
  watch:{
    'formAdd.totalAmount':function (){
      this.formAdd.totalAmount=this.moneyAll
    }
  },
  methods:{
    showEdit(row) {
      if (!row) {
        this.edit=0
        this.title = "新增签约信息";
        this.dialogFormVisible = true;
        this.disabled= false
      } else {
        this.edit=1
        this.title = "编辑签约信息";
        this.getDetail(row.signContractId)
      }
    },
    close(){
      this.$refs["form"].resetFields();
      this.formAdd = this.$options.data().formAdd
      this.dialogFormVisible = false
      this.$emit('close')
    },
    save(){
      this.$refs['form'].validate(async (valid) => {
        if (valid) {
          // const { msg } = await doEdit(this.form)
          // this.$baseMessage(msg, 'success')
          if (this.edit==0){
            this.$emit('add',this.formAdd)
          }else{
            this.$emit('edit',this.formAdd)
          }
          this.dialogFormVisible = false
          this.$refs['form'].resetFields()
        } else {
          return false
        }
      })
    },
    //品牌型号
    getCarModeSelectVo(){
      let data = {
        value:'',enterpriseId:''
      }
      getCarModeSelectVo(data).then(res=>{
        if(res.code === 0){
          this.options = res.data
        }
      })
    },
    //获取品牌
    handleBrand(value){
      this.formAdd.carBrand = value[0]
      this.formAdd.carModel = value[1]
    },
    getCarList(key){
      let form={
        carNum:key,
        // type:[1,2]
      }
      getCarList(form).then(res=>{
        this.carList = res.data
      })
    },
    pickCarNum(cid){
      let obj = {};
      obj =  this.carList.find((item)=>{
        return item.id == cid;//筛选出匹配数据
      });
      this.formAdd.carNum = obj.carNum;
    },
    pickCharger(id){
      let obj = {};
      obj =  this.hList.find((item)=>{
        return item.id == id;//筛选出匹配数据
      });
      this.formAdd.chargerName = obj.realName;
    },
    remoteMethod(query) {
      if (query !== '') {
        this.loading = true;
        setTimeout(() => {
          this.loading = false;
          this.getCarList(query)
        }, 200);
      } else {
        this.carList = [];
      }
    },
    //获取联系人
    getList() {
      // this.cList
      getCList().then(res => {
        if (res.code === 0) {
          this.cList = res.data
        }
      })
    },
    //获取负责人
    getResponsible(){
      getResponsible().then(res => {
        if(res.code === 0){
          this.hList = res.data
        }
      })
    },
    //选择联系人时绑定电话
    handleLiaisonName(value) {
      this.cList.map(item => {
        if (item.name === value) {
          this.form.liaisonPhone = item.telePhone
        }
      })
    },
    //获取客户列表
    remoteCustomer(query){
      if (query !== '') {
        this.loading = true;
        setTimeout(() => {
          this.loading = false;
          this.getCustomer(query)
        }, 200);
      } else {
        this.cList = [];
      }
    },
    getCustomer(key){
      let form={
        page:1,
        rows:10000,
        customerName:key
      }
      getCustomer(form).then(res=>{
        this.cList=res.data.list
      })
    },
    pickCustomer(id){
      let obj = {};
      obj =  this.cList.find((item)=>{
        return item.id == id;//筛选出匹配数据
      });
      this.formAdd.customerName = obj.customerName;
      this.formAdd.customerPhone = obj.customerPhone;
    },
    getDetail(id){
      let form={
        id:id
      }
      getDetail(form).then(res=>{
        this.disabled= true
        this.formAdd= Object.assign({}, this.formAdd, res.data);
        this.dialogFormVisible= true
      })
    }
  }
}
</script>

<style lang="less" scoped>
.el-select{
  width: 60%;
  .el-input{
    width: 100%;
  }
}

</style>
