<template>
  <el-dialog
      :title="title"
      :visible.sync="dialogFormVisible"
      width="60%">
    <el-form ref="form" :model="info"  class="info">
      <el-row :gutter="20">
        <el-col :span="12">
          <el-form-item label="车牌" :label-width="formLabelWidth" prop="carId">
            <el-input v-model="info.carNum" autocomplete="off" placeholder="车牌信息"   maxlength="6"  :disabled="disabled"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="品牌型号" :label-width="formLabelWidth" prop="carModel" >
            <el-input v-model="info.chargerName" autocomplete="off" placeholder="负责人"   maxlength="6"  :disabled="disabled" ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="负责人" :label-width="formLabelWidth" prop="chargerId">
            <el-input v-model="info.carBrand+'/'+info.carModel" autocomplete="off" placeholder="品牌型号"   maxlength="6"  :disabled="disabled" ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="签约客户" :label-width="formLabelWidth" prop="driverId">
            <el-input v-model="info.customerName" autocomplete="off" placeholder="签约用户名"   maxlength="6"  :disabled="disabled" ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="联系方式" :label-width="formLabelWidth" prop="customerPhone">
            <el-input v-model="info.customerPhone" autocomplete="off" placeholder="请输签约用户联系方式" maxlength="11"  :disabled="disabled"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="合约编号" :label-width="formLabelWidth" prop="contractNum">
            <el-input v-model="info.contractNum" autocomplete="off" placeholder="请输合约编号"  :disabled="disabled"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <el-form ref="form" :model="info"  class="info">
      <el-row :gutter="20">
        <el-col :span="12">
          <el-form-item label="签约类型" :label-width="formLabelWidth" prop="signType">
            <el-input v-model="info.signType" autocomplete="off" placeholder="请选择签约类型"  :disabled="disabled"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="签订时间" :label-width="formLabelWidth" prop="signDate">
            <el-input v-model="info.signDate" autocomplete="off" placeholder="请选择年检时间"  :disabled="disabled"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="开始时间" :label-width="formLabelWidth" prop="beginTime">
            <el-input v-model="info.beginTime" autocomplete="off" placeholder="请选择开始时间"  :disabled="disabled"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="结束时间" :label-width="formLabelWidth" prop="endTime">
            <el-input v-model="info.endTime" autocomplete="off" placeholder="请选择结束时间"  :disabled="disabled"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <el-form ref="form" :model="info"  class="info">
      <el-row :gutter="20">
        <el-col :span="12">
          <el-form-item label="付款方式" :label-width="formLabelWidth" prop="contractType">
            <el-input v-model="info.contractType" autocomplete="off" placeholder="付款方式"  :disabled="disabled"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="付款日" :label-width="formLabelWidth" prop="payDate">
            <el-input v-model="info.payDate" autocomplete="off" placeholder="请选择结付款日"  :disabled="disabled"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="分款期数" :label-width="formLabelWidth" prop="signPeriods">
            <el-input v-model="info.signPeriods" autocomplete="off" placeholder="请输入分期期数" :disabled="disabled">
              <span slot="append">期</span>
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="每期租金" :label-width="formLabelWidth" prop="rent">
            <el-input v-model="info.rent" autocomplete="off" placeholder="请输入每期租金" :disabled="disabled">
              <span slot="append">元</span>
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="押金" :label-width="formLabelWidth" prop="depost">
            <el-input v-model="info.depost" autocomplete="off" placeholder="请输入押金" :disabled="disabled">
              <span slot="append">元</span>
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="总金额" :label-width="formLabelWidth" prop="totalAmount">
            <el-input v-model="info.totalAmount" autocomplete="off" placeholder="请输入总金额" :disabled="disabled">
              <span slot="append">元</span>
            </el-input>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <el-form ref="form" :model="info"  class="info">
      <el-row :gutter="20">
        <el-col :span="12">
          <el-form-item label="到期提醒" :label-width="formLabelWidth" prop="remindDay">
            <el-input v-model="info.remindDay" autocomplete="off" placeholder="到期提醒"  :disabled="disabled"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item label="备注" :label-width="formLabelWidth" >
            <el-input v-model="info.remark" autocomplete="off" placeholder="请输入备注"
                      type="textarea"
                      rows="6"
                      maxlength="200"
                      show-word-limit
                      resize="none"
                      :disabled="disabled"
            >
            </el-input>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>

  </el-dialog>
</template>

<script>
import {getDetail} from "@/utils/api/sign";

export default {
  name: "SignDetail",
  data(){
    return{
      title:"",
      dialogFormVisible:false,
      disabled:false,
      formLabelWidth: "80px",
      labelPosition: "right",
      hList: [],//平台列表
      cList: [],//客户列表
      carList:[],
      value: [],
      list: [],
      info:{}
    }
  },
  methods:{
    showEdit(row) {
      this.title = "签约信息详情";
      this.getDetail(row.signContractId)
    },
    getDetail(id){
      let form={
        id:id
      }
      getDetail(form).then(res=>{
        this.disabled= true
        this.info= Object.assign({}, this.info, res.data);
        if (this.info.signType==1){
          this.info.signType='全款购车'
        }
        if (this.info.signType==2){
          this.info.signType='以租代购'
        }
        if (this.info.signType==3){
          this.info.signType='分期购车'
        }
        if (this.info.signType==4){
          this.info.signType='短租'
        }
        this.info.remindDay=this.info.remindDay==1?'提前15天':'提前30天'
        this.info.contractType=this.info.contractType=1?'周付':'月付'
        this.dialogFormVisible= true
      })
    }
  }
}
</script>

<style lang="less">
.el-input.is-disabled{
  .el-input__inner{
    color: #333333;
  }
  .el-input-group__append{
    background-color: #F5F7FA;
  }
}
</style>
