/**
 * @notice 签约相关
 * @author hhq
 */
import http from "../http"

//新增签约
export const addOne =(params)=>{
    return http.postRegister('/signcontract/add',params)
}
//签约列表
export const getList =(params)=>{
    return http.getRequest('/signcontract/page',params)
}
//查看签约详情
export const getDetail =(params)=>{
    return http.getRequest('/signcontract',params)
}
//编辑签约信息
export const editOne =(params)=>{
    return http.postRegister('/signcontract/update',params)
}
