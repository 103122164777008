<template>
  <div class="index-container">
    <div class="bread"> <span @click="home()">操作台 / </span><span class="gray">签约记录</span></div>
    <el-row class="table-body">
      <div class="line clearfix">
        <h5 class="fl title">签约记录列表</h5>
        <div class="fr search">
          <el-input size="small" type="text" placeholder="请输入车牌号" v-model="keywords">
            <template slot="prefix">
              <el-button icon="el-icon-search" type="text" disabled></el-button>
            </template>
          </el-input>
          <el-button type="primary" size="small">查询</el-button>
          <el-button type="primary" size="small" plain @click="addNew">新增</el-button>
        </div>
      </div>
      <div class="list">
        <el-table
            :data="tableData"
        >
          <el-table-column
              fixed
              type="index"
              label="序号">
          </el-table-column>
          <el-table-column
              prop="carNum"
              label="车牌">
          </el-table-column>
          <el-table-column
              prop="carBrand"
              label="品牌型号">
            <template slot-scope="scope">
              <span>{{scope.row.carBrand}}{{scope.row.carMode}}</span>
            </template>
          </el-table-column>
          <el-table-column
              prop="signType"
              label="签约类型">
            <template slot-scope="scope">
              <span v-if="scope.row.signType==1">全款购车</span>
              <span v-if="scope.row.signType==2">以租代购</span>
              <span v-if="scope.row.signType==3">分期购车</span>
              <span v-if="scope.row.signType==4">短租</span>
            </template>
          </el-table-column>
          <el-table-column
              prop="depost"
              label="押金(元)">
          </el-table-column>
          <el-table-column
              prop="rent"
              label="租金(元/月)">
          </el-table-column>
          <el-table-column
              prop="beginTime"
              label="开始时间">
          </el-table-column>
          <el-table-column
              prop="endTime"
              label="结束时间">
          </el-table-column>
          <el-table-column
              prop="customerName"
              label="客户">
          </el-table-column>
          <el-table-column
              prop="chargerName"
              label="负责人">
          </el-table-column>
          <el-table-column
              prop="signPeriods"
              label="签约期数（期）">
          </el-table-column>
          <el-table-column
              prop="signStatus"
              label="签约状态">
            <template slot-scope="scope">
              <span v-if="scope.row.signStatus==0">未生效</span>
              <span v-if="scope.row.signStatus==1">已生效</span>
              <span v-if="scope.row.signStatus==2">签约结束</span>
            </template>
          </el-table-column>
          <el-table-column
              fixed="right"
              label="操作"
              min-width="120">
            <template slot-scope="scope">
              <el-button
                  @click="toDetail(scope.row)"
                  type="text"
                  size="small">
                查看详情
              </el-button>
              <el-button
                  @click="toEdit(scope.row)"
                  type="text"
                  size="small">
                编辑
              </el-button>
              <el-button
                  class="red"
                  type="text"
                  size="small">
                删除
              </el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="pages">
        <el-pagination
            background
            @current-change="handleCurrentChange"
            :hide-on-single-page="showPage"
            layout="prev, pager, next"
            :page-size="pageSize"
            :total="total">
        </el-pagination>
      </div>
    </el-row>
    <edit-sign ref="sign" @add="saveData" @edit="editData"></edit-sign>
    <sign-detail ref="detail"></sign-detail>
  </div>
</template>
<script>
import EditSign from "@/components/EditSign";
import SignDetail from "@/components/SignDetail";
import {addOne, editOne,getList} from "@/utils/api/sign";
export default {
  name: "sign",
  components:{
    EditSign,
    SignDetail
  },
  data(){
    return{
      total:0, //总条数
      pageNo:1,
      pageSize:10,
      showPage:true,
      tableData:[],
      keywords:"",//搜索关键字
      editOne:'', //编辑数据
    }
  },
  created() {
    this.getList()
  },
  methods:{
    home(){
      this.goHome()
    },
    addNew() {
      this.$refs["sign"].showEdit();
    },
    toEdit(row){
      this.$refs["sign"].showEdit(row);
    },
    toDetail(row){
      this.$refs["detail"].showEdit(row);
    },
    handleCurrentChange(val) {
      this.pageNo = val;
      this.getList()
    },
    getList(){
      let form = {
        pages:this.pageNo,
        rows:this.pageSize
      }
      if (this.keywords!=""){
        form.carNum = this.keywords
      }
      getList(form).then(res=>{
        if (res.code==0){
          this.tableData= res.data.records
          this.total= res.total
        }
      })
    },
    saveData(e){
      addOne(e).then(res=>{
        if (res.code==0){
          this.$baseMessage('签约记录添加成功','success')
          setTimeout(()=>{
            this.getList()
          }, 100)
        }else{
          this.$baseMessage(res.msg,'error')
        }
      })
    },
    editData(e){
      editOne(e).then(res=>{
        if (res.code==0){
          this.$baseMessage('签约记录修改成功','success')
          setTimeout(()=>{
            this.getList()
          }, 100)
        }else{
          this.$baseMessage(res.msg,'error')
        }
      })
    }
  }
}
</script>

<style lang="less">
.table-body{
  background-color: #FFFFFF;
  padding:30px ;
  padding-top: 0;
  .line{
    height: 90px;
    line-height: 90px;
    .title{
      font-size: 16px;
      font-weight: 800;
      color: #333333;
    }
    .search{
      display: flex;
      align-items: center;
      align-content: center;
      justify-items: flex-end;

      .el-input{
        font-size: 12px;
        margin-right: 12px;

        .el-input__inner{
          border: none;
          background-color: #FAFAFA;
          padding-left: 50px;
        }
        .el-input__prefix{
          width: 40px;
          &:after{
            content: '';
            width: 1px;
            height: 15px;
            background-color: #D8D7D7;
            position: absolute;
            right: 0;
            top: 50%;
            transform: translateY(-50%);
          }
        }
      }

    }
  }
}
</style>
